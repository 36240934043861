.viewproductlist {
  padding: 30px 80px;
  @media (max-width: 480px) {
    padding: 30px 25px;
  }
  h5 {
    font-size: 22px;
    margin-bottom: 40px;
    color: #808080;
    display: flex;
    font-family: "Plus Jakarta Sans", sans-serif;


    @media (max-width:1500px) {
      font-size: 20px;
     
  }
  @media (max-width:1380px) {
    font-size: 19px;
  }
  @media (max-width:1280px) {
    font-size: 18px;
  }
    
    span {
      color: var(--primary-color);
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        font-size: 14px;
        margin: 0 4px;
      }
    }
    @media (max-width: 640px) {
      font-size: 14px;
    }
    @media (max-width: 400px) {
      font-size: 12px;
    }
  }
  .sub_productlist {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    .sub_product_item {
      width: 48%;
      background-color: var(--bg_item);
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 12px;
      @media (max-width: 1160px) {
        width: 47%;
        flex-direction: column-reverse;
        gap: 20px;
      }
      //   @media (max-width: 1020px) {
      //     width: 47%;
      //   }
      //   @media (max-width: 910px) {
      //     width: 47%;
      //   }
      @media (max-width: 830px) {
        width: 46%;
      }
      @media (max-width: 660px) {
        width: 100%;
        flex-direction: column-reverse;
        gap: 20px;
        align-items: start;
      }
    }
    .item_contents {
      width: 56%;

      @media (max-width: 1160px) {
            width: 80%;
      }
      @media (max-width: 660px) {
        width: 100%;
      }
      h6 {
        font-size: 26px;
        font-weight: 500;
        color: var(--font-color);
        margin-bottom: 16px;
        font-family: "Plus Jakarta Sans", sans-serif;

        @media (max-width:1500px) {
          font-size: 24px;
         
      }
      @media (max-width:1380px) {
        font-size: 22px;
      }
      @media (max-width:1280px) {
        font-size: 20px;
      }

      }
      p {
        font-size: 22px;
        font-weight: 400;
        color: #808080;
        margin-bottom: 14px;
        font-family: "Plus Jakarta Sans", sans-serif;


               @media (max-width:1500px) {
                font-size: 20px;
               
            }
            @media (max-width:1380px) {
              font-size: 18px;
            }
            @media (max-width:1280px) {
              font-size: 16px;
            }

      }
      button {
        display: flex;
        align-items: center;
        border: none;
        outline: none;
        color: var(--primary-color);
        background-color: transparent;
        font-family: "Plus Jakarta Sans", sans-serif;
        cursor: pointer;
      }
    }
    .item_image {
      width: 30%;
      img {
        display: block;
        width: 100%;
      }
    }
  }
}
