.contact_us_container {
  padding: 100px 80px;
  display: flex;
  background-color: #f0f0f0;
  margin-top: 20px;

  @media (max-width: 780px) {
    flex-direction: column;
  }

  @media (max-width: 480px) {
    padding: 30px 25px;
  }

  .contact_us_container_sub_1 {
    width: 44%;
    padding-right: 50px;

    @media (max-width: 780px) {
      width: 100%;
    }

    h6 {
      font-size: 46px;
      font-weight: 600;
      color: var(--font-color);
      margin-bottom: 20px;
      font-family: "Titillium Web", sans-serif;
      @media (max-width: 480px) {
        font-size: 38px;
      }
    }

    p {
      color: #6f6f6f;
      margin-bottom: 20px;
      font-size: 22px;

      @media (max-width: 1500px) {
        font-size: 20px;
      }
      @media (max-width: 1380px) {
        font-size: 18px;
      }
      @media (max-width: 1280px) {
        font-size: 16px;
      }
    }
  }

  .contact_us_container_sub_2 {
    width: 55%;

    @media (max-width: 780px) {
      width: 100%;
    }

    form {
      // padding:30px ;
      border-radius: 30px;
      background-color: #f0f0f0;

      @media (max-width: 780px) {
        margin-top: 20px;
      }

      .form_input_container {
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;

        .input_container {
          width: 48%;
          margin-bottom: 20px;

          label {
            display: block;
            font-weight: 600;
            font-size: 16px;
          }
          input {
            padding: 12px 15px;
            border-radius: 10px;
            width: 100%;
            font-size: 20px;

            @media (max-width: 1500px) {
              font-size: 18px;
            }
            @media (max-width: 1380px) {
              font-size: 16px;
            }
            @media (max-width: 1280px) {
              font-size: 14px;
            }
          }

          @media (max-width: 1180px) {
            width: 100%;
          }
        }
      }

      .message_input_container {
        margin-bottom: 20px;
        width: 100%;
        display: block;

        label {
          font-weight: 600;
        }

        textarea {
          padding: 15px;
          border-radius: 10px;
          width: 100%;
          height: 100px;
          font-family: Arial, Helvetica, sans-serif;
          font-size: 20px;

                 // @media (max-width:1500px) {
            //     font-size: 22px;
               
            // }
            @media (max-width:1380px) {
                font-size: 17px;
              }
              @media (max-width:1280px) {
                font-size: 16px;
              }

        }
      }

      button {
        padding: 14px 50px;
        background-color: #f2682c;
        color: white;
        font-size: 18px;
        border-radius: 10px;

               @media (max-width:1500px) {
                font-size: 17px;
               
            }
            @media (max-width:1380px) {
                font-size: 15px;
              }
              @media (max-width:1280px) {
                font-size: 14px;
              }
      }
    }
  }
}
.contacts {
  padding: 0;
  li {
    font-size: 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    @media (max-width: 1500px) {
      font-size: 19px;
    }
    @media (max-width: 1380px) {
      font-size: 18px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
    }

    svg {
      margin-right: 8px;
    }
  }
}


