// header {
//   height: 80px;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 0 80px;
//   position: fixed;
//   width: 100%;
//   top: 0;
//   left: 0;
//   z-index: 1000;
//   background-color: transparent;
//   transition: background-color 0.3s ease-in-out,
//     backdrop-filter 0.3s ease-in-out;

//     @media (max-width: 480px) {
//       padding: 0 30px;
//     }

//   h1 {
//     width: 130px;
//     img {
//       width: 100%;
//       display: block;
//       filter: brightness(0) invert(1);
//     }
//   }

//   .nav_bar {
//     display: flex;
//     align-items: center;
//     gap: 20px;
//     @media (max-width: 920px) {
//       display: none;
//   }

//     li {
//       font-size: 18px;
//       font-weight: 500;
//       color: #fff;

//       @media (max-width: 1130px) {
//         font-size: 14px;
//     }

//     @media (max-width: 990px) {
//       font-size: 12px;
//   }
//     }
//   }

//   &.scrolled {
//     background-color: #ffffff2c;
//     backdrop-filter: blur(10px);
//     img {
//       filter: none;
//     }
//     .nav_bar li {
//       color: #000;
//     }
//   }
// }

// // .mobile_nav {

// //   position: fixed;
// //   width: 100%;
// //   height: 100vh;
// //   background-color: white;
// //   top: 0;
// //   left: 0;

// // }

// .mobile_nav {
//   position: fixed;
//   width: 100%;
//   height: 100vh;
//   background-color: white;
//   top: 0;
//   left: 0;
//   z-index: 1000; /* Ensure it's above other content */
// }

header {
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 80px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out,
    backdrop-filter 0.3s ease-in-out;
  // background-color: #0000001f;
  backdrop-filter: blur(10px);

  @media (max-width:1380px) {

    padding: 0 70px;
  
  }

  @media (max-width: 480px) {
    padding: 0 30px;
  }

  h1 {
    width: 175px;

    img {
      width: 100%;
      display: block;
      filter: brightness(0) invert(1);
    }

    @media (max-width:1500px) {
      width: 165px;
  }

  @media (max-width:1380px) {
    width: 155px;

  
  }
  // @media (max-width:1280px) {
  //   width: 153px;
  // }

  }

  .nav_bar {
    
    display: flex;
    align-items: center;
    gap: 20px;
    @media (max-width: 920px) {
      display: none;
    }

    li {
      font-size: 21px;
      font-weight: 500;
      color: #fff;
      transition: 0.2s ease-in;
      cursor: pointer;

      @media (max-width:1500px) {
        font-size: 19px;
    }
    @media (max-width:1380px) {
      font-size: 17px;
    }
  

      @media (max-width: 1180px) {
        font-size: 14px;
      }

      @media (max-width: 1020px) {
        font-size: 12px;
      }

      &:hover {
        color: #f2a48293;
      }
    }
  }

  &.scrolled {
    background-color: #ffffff42;
    backdrop-filter: blur(10px);
    img {
      filter: none;
    }
    .nav_bar li {
      color: #000;
      transition: 0.2s ease-in;
      cursor: pointer;
      &:hover {
        color: #f2a48293;
      }
    }
  }
}

.mobile_nav {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: white;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 0;
  li {
    margin: 10px 0;
    font-size: 24px;
    font-weight: 500;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    font-size: 16px;

    @media (max-width: 480px) {
      padding: 0 20px;
    }

    svg {
      color: #6f6f6f;
      font-size: 24px;
    }
  }

  .nav_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    width: 100%;
    padding: 0 50px;

    @media (max-width: 480px) {
      padding: 0 20px;
    }

    .logo_mobile {
      width: 120px;

      img {
        width: 100%;
        display: block;
      }
    }

    .closeSvg {
      width: 30px;
    }
  }
}
// .nav_bar li a.active,
// .mobile_nav li a.active,
// .scrolled .nav_bar li a.active {
//   color: var(--primary-color);
// }
.nav_bar li.active,
.mobile_nav li.active,
.scrolled .nav_bar li.active {
  color: var(--primary-color);
}
.achiemnts{
  width: 100%;
}

.ToOrange {
  filter: none;
}
