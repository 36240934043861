// .catalogue {
//   padding: 30px 80px;

//   @media (max-width: 480px) {
//     padding: 30px 25px;
//   }

//   h5 {
//     font-size: 46px;
//     font-weight: 600;
//     color: var(--font-color);
//     margin-bottom: 40px;
//     font-family: "Titillium Web", sans-serif;

//     @media (max-width: 520px) {
//       font-size: 38px;
//     }
//   }

//   .catelogue_items {
//     display: flex;
//     gap: 50px;

//     @media (max-width: 480px) {
//       flex-direction: column;
//       align-items: center;
//       justify-content: center;
//     }

//     .Items_wrapper {
//       width: 15%;
//       display: flex;
//       align-items: end;
//       justify-content: center;
//       flex-direction: column;

//       // @media (max-width: 480px) {
//       //     width: 50%;
//       // }

//       @media (max-width: 840px) {
//         width: 23%;
//       }
//       @media (max-width: 730px) {
//         width: 19%;
//       }
//       @media (max-width: 650px) {
//         width: 20%;
//       }
//       @media (max-width: 590px) {
//         width: 24%;
//       }
//       @media (max-width: 480px) {
//         width: 40%;
//       }

//       .imgWrapper {
//         width: 100%;
//         margin-bottom: 15px;

//         img {
//           width: 100%;
//           display: block;
//         }
//       }
//       a {
//         width: 100%;

//         button {
//           // padding: 10px 20px;
//           height: 32px;
//           width: 100%;
//           background-color: rgba(255, 68, 0, 0.788);
//           border-radius: 8px;
//           outline: none;
//           color: #fff;
//           cursor: pointer;

//           @media (max-width: 840px) {
//             height: 28px;
//           }

//           @media (max-width: 480px) {
//             height: 28px;
//           }
//         }
//       }
//     }
//   }
// }

.catalogue {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  z-index: 1000;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  @media (max-width: 640px) {
    width: 80%;
  }

  @media (max-width: 480px) {
    padding: 30px 25px;
    width: 94%;
  }

  h5 {
    font-size: 30px;
    font-weight: 600;
    color: var(--font-color);
    margin-bottom: 20px;
    font-family: "Titillium Web", sans-serif;
    @media (max-width: 480px) {
      font-size: 28px;
    }
  }
  .close-button {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 20px;
  }

  .catelogue_items {
    display: flex;
    gap: 50px;
    justify-content: center;

    @media (max-width: 480px) {
      //   flex-direction: column;
      //   align-items: center;
      //   justify-content: center;
      gap: 26px;
    }

    .Items_wrapper {
      width: 40%;
      display: flex;
      align-items: end;
      justify-content: center;
      flex-direction: column;

      .imgWrapper {
        width: 100%;
        margin-bottom: 15px;

        img {
          width: 100%;
          display: block;
        }
      }
      a {
        width: 100%;

        button {
          height: 32px;
          width: 100%;
          background-color: rgba(255, 68, 0, 0.788);
          border-radius: 8px;
          outline: none;
          color: #fff;
          cursor: pointer;

          @media (max-width: 840px) {
            height: 28px;
          }

          @media (max-width: 480px) {
            height: 28px;
          }
        }
      }
    }
  }
}

.catalogue-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
