.similar_product {
  padding: 30px 80px;

  
  @media (max-width: 480px) {
    padding: 30px 25px;
  }
  
  h5 {
    font-size: 20px;
    font-weight: 500;
    color: var(--font-color);
    margin-bottom: 30px;
  }
  .similar_list {
    display: flex;
    justify-content: space-between;
    .similar_item {
      border-radius: 12px;
      padding: 24px;
      background-color:#fff;
      width: 90%;
      // height: 350px;
      text-align: center;

      .img_div {
        width: 40%;
        height: 150px;
        margin: 0 auto;
        // background-color: rgb(233, 23, 23);
        // margin-bottom: 14px;
        overflow: hidden;


        img {
          width: 100%;
          // display: flex;
          // align-items: center;
          // justify-content: center;
          // margin: 0 auto;
       

        }
      }
      
      h6 {
        font-size: 20px;
        font-weight: 500;
        color: var(--font-color);
        margin-bottom: 14px;
        text-align: left;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: #808080;
        text-align: left;
        margin-bottom: 14px;
      }
      button {
        display: flex;
        align-items: center;
        border: none;
        outline: none;
        color: var(--primary-color);
        background-color: transparent;
        cursor: pointer;
      }
    }
  }
}
.slick-slider {
  width: 100%;
}


.slick-slider {
  width: 100%;
  position: relative; // Make sure the container is positioned relative for absolute positioning
}

.slick-prev,
.slick-next {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-color); // Background color for the arrows
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  // opacity: 0.75;
  transition: opacity 0.3s;
  position: absolute; // Position absolutely to place them in the desired spot
  top: 10px; // Adjusts how far from the top the arrows appear
}


.slick-prev {
  left: 990px;
}

.slick-next {
  right: 38px; // Aligns the next arrow at the far right
}

// .slick-prev:hover,
// .slick-next:hover {
//   opacity: 1;
// }
.slick-prev, .slick-next {
  top: -34px;
}
.arrowleft_slide{
  position: absolute;
  right: 20px;
  top: -45px;
}
.arrowright_slide{
  position: absolute;
  right: 80px;
  top: -45px;
}