
// .gallery {
//   width: 100%;
//   height: 800px;

// }

.gallery_container {
  width: 100%;
  height: 100%;
  
  // padding: 100px ;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // padding: 10px;
  // background-color: #f0f0f0; // Adjust as necessary

  
  // @media (max-width: 750px) {
  //   flex-direction: column;
  // }

    h6 {
      font-size: 46px;
        font-weight: 600;
        color: var(--font-color);
        margin-bottom: 30px;
        margin-left: 80px;
        font-family: "Titillium Web", sans-serif;

        @media (max-width: 480px) {
          margin-left: 20px;;
        }
    }

  .collage_container {

    width: 100%;
    height: 100%;
    padding: 20px 70px ;

    display: flex;

    @media (max-width: 750px) {
      flex-direction: column;
    }

    @media (max-width: 480px) {
      padding: 0px 20px;
    }

    .collage_section_1{
      width: 48%;
      height: 100%;
      // display: flex;
      // flex-direction: column;
      // gap: 20px;

      
    @media (max-width: 750px) {
      width: 100%;
    }

      .collage_section_1_half_1 {

        display: flex;
        height: 49%;
        // background-color: aliceblue;
        // margin-bottom: 20px;
      
      .colum_vertical {
        display: flex;
        flex-direction: column;
        padding: 10px;
        width: 50%;
        height: 100%;
        gap: 20px;

        .colum_vertical_img_div {
            width: 100%;
            height: 49.5%;
            display: flex;
            align-items: center;
            justify-content: center;
            // border-radius: 15px;
            // box-shadow: --v;
            // color: var(--font-color);
            // box-shadow: var(--shadow);
            padding: 10px;

            // background-color: var(--demoColor);
            // border: 1px solid rgba(0, 0, 0, 0.185);
            padding: 6px;
            border-radius: 5px;
            // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.068), 0 1px 2px rgba(0, 0, 0, 0.055);

            


            @media (max-width: 480px) {
              border-radius: 10px;
            }
            
            img {
              width: 80%;
              display: block;
            }
        }

      }

      .colum_Big_img_vertical {
        padding: 10px;
        width: 50%;
        height: 100%;

        .colum_Big_img_div {
          width: 100%;
          height: 100%;
          // box-shadow: var(--shadow);

          // background-color: var(--demoColor);

          // border: 1px solid black;


          @media (max-width: 480px) {
            border-radius: 10px;
          } 

          img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: contain;
          }


        }
      }



    }

    .collage_section_1_half_2 {
      width: 100%;
      height: 100%;
      padding: 10px;

      .collage_section_1_half_2_img_main {

        // background-color: aliceblue;
        // box-shadow: var(--shadow);
        width: 100%;
        height: 50%;
        // border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

        // background-color: var(--demoColor);
        // border: 1px solid black;
        // border: 1px solid rgba(0, 0, 0, 0.185);
        // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.068), 0 1px 2px rgba(0, 0, 0, 0.055);
        padding: 6px;
       



        @media (max-width: 480px) {
          border-radius: 10px;
        }


       


        img {
          width: 90%;
          // height: 100%;
          display: block;
          @media (max-width: 750px) {
            width: 60%;
          }

          @media (max-width: 480px) {
            // width: 60% ;
          }
          


        }
      }

    }

    }
    .collage_section_2 {
      width: 48%;
      // height: 100%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      // background-color: rgba(10, 68, 119, 0.596);



      
    @media (max-width: 750px) {
      flex-direction: column-reverse;
       width: 100%;
    }

      // .collage_section_2_half_1 {

      //   width: 100%;
      //   height: 60%;
      //   // background-color: rgb(128, 11, 11);

      //   .collage_section_2_half_1_main_img {
      //     width: 100%;
      //     height: 93%;
      //     display: flex;
      //     align-items: center;
      //     justify-content: center;
      //     box-shadow: var(--shadow);
      //     border-radius: 15px;

      //     @media (max-width: 480px) {
      //       border-radius: 10px;
      //     }


           
      //     img {
      //       width: 70%;
      //       display: block;
      //     }
      //   }

      // }

      .collage_section_2_half_2 {
        height: 50%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        // background-color: aquamarine;

        .collage_section_2_half_2_subs {
          width: 100%;
          height: 49%;
          display: flex;
          gap: 20px;

            // .collage_section_2_half_2_subs_img_div {
            //   width: 48%;
            //   height: 100%;
            //   display: flex;
            //   align-items: center;
            //   justify-content: center;
            // //   box-shadow: var(--shadow);
            // // border-radius: 15px;

            // background-color: var(--demoColor);
            // border: 1px solid black;


            // @media (max-width: 480px) {
            //   border-radius: 10px;
            // }
        


            // img {
            //   width: 80%;
            //   display: block;
            // }

            // }

        }





      }


      
    }

  }
}


.collage_section_2_half_1 {
  height: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  // background-color: aquamarine;

  .collage_section_2_half_2_subs {
    width: 100%;
    height: 49%;
    display: flex;
    gap: 20px;



  }



}

.img_wrapper__ {
  // border: 1px solid black;
  // padding: 10px;
  background-color: var(--demoColor);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  padding: 15px;

}


.collage_section_2_half_2_subs_img_div {
  width: 48%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // box-shadow: var(--shadow);
border-radius: 5px;

// border: 1px solid rgba(255, 255, 255, 0.185);
// box-shadow: 0 1px 3px rgba(0, 0, 0, 0.068), 0 1px 2px rgba(0, 0, 0, 0.055);
padding: 6px;


@media (max-width: 480px) {
  border-radius: 10px;
}



img {
  width: 80%;
  display: block;
}

}






















// --------------------------------------------------------------

// .gallery_container {
//   padding: 30px 80px;
//   @media (max-width: 480px) {
//     padding: 0 30px;
//   }

//   h6 {
//     font-size: 46px;
//     font-weight: 600;
//     color: var(--font-color);
//     margin-bottom: 40px;
//     font-family: "Titillium Web", sans-serif;
//     @media (max-width:520px) {

//       font-size: 38px;
  
// }
//   }

//   .gallery_item_container {
//     display: flex;
//     // justify-content: space-between;

//     .gallery_item_container_sub,
//     .gallery_item_container_sub_middle,
//     .gallery_item_container_sub_3 {
//       width: 32%;
//       display: flex;
//       flex-direction: column;
//     }

//     .slick-slide {
//       display: flex;
//       justify-content: center;
//       // background-color: #fff;
//       gap: 20px;
//       .sliderone {
//         background-color: #fff;
//         width: 370px !important ;
//         height: 250px;
//         padding: 20px;
//         margin-bottom: -10px;

//         @media(max-width:1400px) {
//           width: 360px !important ;
//         } 
//         @media(max-width:1330px) {
//           width: 340px !important ;
//         } 
//         @media(max-width:1283px) {
//           width: 330px !important ;
//         } 
//         @media(max-width:1240px) {
//           width: 310px !important ;
//         } 
//         @media(max-width:1190px) {
//           width: 300px !important ;
//         } 
//         @media(max-width:1150px) {
//           width: 285px !important ;
//         } 
//         @media(max-width:1096px) {
//           width: 265px !important ;
//         } 
//         @media(max-width:1050px) {
//           width: 250px !important ;
//         } 
//         @media(max-width:990px) {
//           width: 230px !important ;
//         } 
//         @media(max-width:920px) {
//           width: 210px !important ;
//         } 
//         @media(max-width:860px) {
//           width: 190px !important ;
//         } 
//         @media(max-width:802px) {
//           width: 170px !important ;
//         } 
//         @media(max-width:725px) {
//           width: 160px !important ;
//         } 
//         @media(max-width:692px) {
//           width: 150px !important ;
//         } 
          

//         img {
//           width: 100%;
//           height: 200px;
//           margin: 0 auto;
//           background-size: contain;
//           display: block;
//           object-fit: contain;

          
          

//         }
//       }
//     }

//     // .gallery_item_container_sub_middle .slick-slide .sliderone {
//     //   background-color: #b2b8ff;
//     // }

//     // .gallery_item_container_sub_3 .slick-slide .sliderone {
//     //   background-color: #fffadc;
//     // }
//   }
// }
// .secondSlide {
//   margin-top: 80px;
// }
// .single_slide{
//   // width: 300px;
//   width: 100%;
//   height: 200px;
//   img{
//     width: 100%;
//     display: block;
//     height: 200px;
//   }
// }
// .slick-slider .slick-track, .slick-slider .slick-list{
//   width: 100%;
// }

