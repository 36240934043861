.about{
    padding: 30px 80px;

    // background-image: url("../../assets/images/baground\ shapes.png");
    // background-attachment: fixed;

    @media (max-width: 480px) {
        padding: 30px 25px;

    }

    h6{
        font-size: 46px;
        font-weight: 600;
        color: var(--font-color);
        margin-bottom: 20px;
        font-family: "Titillium Web", sans-serif;

         @media (max-width: 480px) {
        // padding: 30px 25px;
    }
    @media (max-width:520px) {

        font-size: 38px;
    
}
    }
    .about_list {
        .about_item {
            display: flex;
            align-items: center;
            justify-content: space-between;


            @media (max-width: 480px) {
                flex-direction: column;

            }



            .rubber_img{
                width: 22%;

                @media (max-width: 480px) {
                    width: 100%;
                    
                }


                img{
                    display: block;
                    width: 100%;
                    border-radius: 20px;

                    @media (max-width: 480px) {
                        border-radius: 5px;
                    }
                }
            }
            .rubber_content{
                width: 75%;
                display: flex;
                flex-direction: column;
                gap: 25px;

                @media (max-width: 480px) {
                    width: 100%;
                    margin-top: 30px;
                    gap: 5px;
                }

                h5 {
                    font-size: 30px;
                    font-weight: 600;
                    color: var( --font-color);
                    font-family: "Titillium Web", sans-serif;
                    @media (max-width:480px) {

                        font-size: 24px;
                    
                }
                }

                ul {
                    padding: 20px;
                    list-style: disc;
                    color: var(--para_color) !important;
                    font-size: 22px;

                    @media (max-width:1500px) {
                        font-size: 18px;
                    }
                    @media (max-width:1380px) {
                        font-size: 16px;
                    }

                    li {

                        span {
                            font-weight: 500;
                        }
                    }
                }

                p {
    
                     color: var(--para_color);
                    font-family: "Plus Jakarta Sans", sans-serif;
                    line-height: 27px;
                    font-size: 22px;

                    @media (max-width:1500px) {
                        font-size: 18px;
                        line-height: 26px;
                    }
                    @media (max-width:1380px) {
                        font-size: 16px;
                    }

  


                }
            }

        }

        .childsecond {

            @media (max-width: 480px) {
                flex-direction: column-reverse;
            }
        }

        
    }
}

.about_item_mobile {
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: space-between;

    
    ul {
        padding: 20px;
        list-style: disc;
        color: var(--para_color) !important;

        li {

            span {
                font-weight: 500;
                
            }
        }
    }

    .image_header_container {
        display: flex;
        gap: 20px;
        margin-bottom: 20px;

        h5 {
            font-size: 30px;
            @media (max-width: 960px) {
                font-size: 25px;
            }
            @media (max-width: 860px) {
                font-size: 23px;
            }
            @media (max-width: 750px) {
                font-size: 20px;
            }
            @media (max-width: 580px) {
                font-size: 16px;
            }



        }

        p {
            font-size: 15px;
            
            @media (max-width: 860px) {
                font-size: 13px;
            }
        }
    
    .rubber_img{
        width: 15%;
        

        @media (max-width: 960px) {
            width: 16%;
        }
        @media (max-width: 860px) {
            width: 18%;
        }
        @media (max-width: 750px) {
            width: 20%;
        }
        @media (max-width: 580px) {
            width: 25%;
        }

        



        img{
            display: block;
            width: 100%;
            border-radius: 20px;
        }
    }
}
}

.childsecond {

    @media (max-width: 480px) {
        flex-direction: column-reverse;
    }
}


// .about_item_small {

// }


  


