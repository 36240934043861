// .imageAlert {

//     width: 80%;
//     height: 80%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     background-color: rgba(0, 0, 0, 0.103);
//     position: fixed;

//     .alert_img_wrapper {
//         width: 80px;

//         img {
//             width: 100%;
//             display: block;
//         }
//         button {
//             padding: 8px 50px;
//             background-color: orangered;
//             border-radius: 10px;
//             color: white;
//             outline: none;

//         }
//     }
// }

.imageAlert {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(
    0,
    0,
    0,
    0.6
  ); /* Increased opacity for better visibility */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000; /* Ensure it is above other content */
  

  .alert_img_wrapper {
    position: relative;
    max-width: 90%;
    max-height: 90%;

    img {
      width: 100%;
      height: 500px;
      display: block;
    }

    button {
      position: absolute;
      top: 10px;
      right: 10px;
      height: 30px;
      width: 30px;
      background-color: rgba(0, 0, 0, 0.428);
      border: none;
      border-radius: 50%;
      color: white;
      cursor: pointer;
      outline: none;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
