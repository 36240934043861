.footer {
  background-color: #000;
  padding: 30px 0 20px;



  footer {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--primary-color);
    padding: 0 80px 30px;
    @media (max-width: 800px) {
      flex-wrap: wrap;
      gap: 20px;


    }
  @media (max-width: 480px) {
    padding: 0 30px 25px;
  }
    ul {
      width: 30%;
      @media (max-width: 800px) {
        width: 47%;
      }


      @media (max-width: 1060px) {
        padding: 0 20px;

      }

      @media (max-width: 640px) {
        width: 100%;
      }
      @media (max-width: 480px) {
        padding: 0;
      }
      h6 {
        font-size: 22px;
        font-weight: 500;
        color: #fff;
        margin-bottom: 24px;

 
      }
      li {
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        margin-bottom: 12px;
        @media (max-width:480px) {

          font-size: 16px;
      
  }

        &:hover {
          color: #fcfafabe;
          transition: 0.2s ;
          cursor: pointer;

       
        }

        span {
          color: var(--primary-color);
          
        }

       
      }

      .iso_logo {

        display: flex;
    flex-direction: column;
    align-items: center;
      
    
        @media (max-width: 1070px) {
          width: 48%;
        }
        @media (max-width: 950px) {
          width: 60%;
        }
        @media (max-width: 830px) {
          width: 90%;
        }
        @media (max-width: 640px) {
          width: 30%;
        }
        @media (max-width: 370px) {
          width: 40%;
        }
        .iso_img {
          width: 120px;
          height: 120px;
          // display: flex;
          // // align-items: center;
          // justify-content: center;
          background-color: #fff;
          border-radius: 50%;
          margin-bottom: 5px;


          img {
            width: 100%;
          }
        }
        h3 {
          font-size: 10px;
          display: flex;
          flex-direction: column;
          display: flex;
          // align-items: center;
          justify-content: center;
          text-align: center;

        }
        span {
          font-size: 7px;
        }
      }

      .connect {
        font-weight: 500;
        font-size: 22px;
      }
      .social {
        display: flex;
        gap: 10px;
      }
    }
  }
  .footer_bottom {
    display: flex;
    justify-content: space-between;
    padding: 20px 80px 0;
    @media (max-width: 640px) {
      flex-wrap: wrap;
      gap: 10px;
    }
    @media (max-width: 480px) {
      padding: 30px 25px;
    }


    p {
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      display: flex;
      align-items: center;
      @media (max-width:480px) {

        font-size: 12px;
    
}
      span {
        color: var(--primary-color);
        margin-right: 4px;
      }
    }
  }
}

.logo_contianer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  padding: 0 !important;
}
