.OringGasket {
  padding: 70px 100px;

  @media (max-width: 780px) {
    padding: 50px;
  }

  @media (max-width: 480px) {
    padding: 50px 30px;
  }

  .content_head {
    h6 {
      font-size: 22px;
      margin-bottom: 40px;
      color: #808080;
      display: flex;
      font-family: "Plus Jakarta Sans", sans-serif;
      cursor: pointer;

      

      
    @media (max-width:1500px) {
      font-size: 20px;
     
  }
  @media (max-width:1380px) {
    font-size: 19px;
  }
  @media (max-width:1280px) {
    font-size: 18px;
  }

      span {
        color: var(--primary-color);
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    h5 {
      font-size: 34px;
      margin-bottom: 20px;
      font-weight: 600;
      font-family: "Titillium Web", sans-serif;


      @media (max-width:1500px) {
        font-size: 33px !important;
       
    }
    @media (max-width:1380px) {
      font-size: 32px !important;
    }
    @media (max-width:1280px) {
      font-size: 30px !important;
    }

    }
    p {
      margin-bottom: 38px;
      font-size: 20px;
      font-family: "Plus Jakarta Sans", sans-serif;

      @media (max-width:1500px) {
        font-size: 18px;
       
    }
    @media (max-width:1380px) {
      font-size: 17px;
    }
    @media (max-width:1280px) {
      font-size: 16px;
    }

      
    }
  }
  .sub_content {
    display: flex;
    // gap: 10px;
    // padding: 20px;
    justify-content: space-between;
    margin-bottom: 40px;

    @media (max-width: 610px) {
      flex-direction: column;
    }

    .content_ {
      h3 {
        font-size: 22px;
        font-family: "Titillium Web", sans-serif;
      }

      ul {
        padding: 12px 20px;
        list-style: disc;
        font-family: "Plus Jakarta Sans", sans-serif;

         li {
          font-size: 18px;

          @media (max-width:1500px) {
            font-size: 16px;
           
        }
        @media (max-width:1380px) {
          font-size: 15px;
        }
        @media (max-width:1280px) {
          font-size: 14px;
        }


         }
      }
    }
  }
  .First_image_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 80px;

    @media (max-width: 480px) {
      flex-direction: column;
    }

    .image_container {
      width: 30%;

      @media (max-width: 580px) {
        width: 45%;
      }

      @media (max-width: 480px) {
        width: 90%;
      }

      img {
        width: 100%;
        border-radius: 20px;
      }
    }

    @media (max-width: 580px) {
      .removable {
        display: none;
      }
    }
  }

  .pressure_cooker_section {
    h3 {

      font-size: 30px;
    font-weight: 600;
      margin-bottom: 20px;
      font-family: "Titillium Web", sans-serif;
    }

    p {
      font-size: 22px;
      margin-bottom: 38px;
      font-family: "Plus Jakarta Sans", sans-serif;

      @media (max-width:1500px) {
        font-size: 18px;
       
    }
    @media (max-width:1380px) {
      font-size: 17px;
    }
    @media (max-width:1280px) {
      font-size: 16px;
    }
    }

    .image_text_container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 1000px) {
        flex-direction: column-reverse;
      }

      .text_container_ {
        width: 76%;

        @media (max-width: 1000px) {
          width: 100%;
        }

        h4 {
          font-size: 22px;
          font-family: "Titillium Web", sans-serif;
        }

        ul {
          padding: 12px 20px;
          list-style: disc;

          li {

            font-size: 18px;


            @media (max-width:1500px) {
              font-size: 16px;
             
          }
          @media (max-width:1380px) {
            font-size: 15px;
          }
          @media (max-width:1280px) {
            font-size: 14px;
          }
          }
        }
      }

      .img_container {
        width: 29%;

        @media (max-width: 1000px) {
          width: 50%;
        }
        @media (max-width: 480px) {
          width: 100%;
        }

        img {
          width: 100%;
        }
      }
    }

    // .image_container {
    //     width: 48%;

    //     img {
    //         width: 100%;
    //     }

    // }
  }
}
.paraHead {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px;
}
