// .spotlight {
//   background: url(../../assets/images/slider_chandra.webp);
//   background-repeat: no-repeat;
//   background-size: cover;
//   width: 100%;
//   height: 100vh;
//   .spotlight_container {
//     display: flex;
//     align-items: center;
//     padding: 0 50px;
//     height: calc(100vh - 80px);

//     @media (max-width: 480px) {
//       padding: 0 30px;
//     }
//     .content {
//       width: 70%;

//       @media (max-width: 600px) {
//         width: 90%;
//       }
//       h4 {
//         font-size: 54px;
//         font-weight: 700;
//         color: var(--primary-color);
//         margin-bottom: 20px;

//         @media (max-width: 740px) {
//           font-size: 42px;
//         }

//         @media (max-width: 480px) {
//           font-size: 34px;
//         }
//         @media (max-width: 400px) {
//           font-size: 30px;
//         }
//       }
//       p {
//         font-size: 20px;
//         color: #fff;

//         @media (max-width: 740px) {
//           font-size: 18px;
//         }

//         @media (max-width: 480px) {
//           font-size: 16px;
//         }
//         @media (max-width: 400px) {
//           font-size: 14px;
//         }
//       }
//     }
//   }
// }
// .slide2 {
//   background: url(../../assets/images/engineer2slide.webp);
// }
// .slid3 {
//   //   background: url(../../assets//images/slid_img_3.png);
//   width: 100%;
//   height: 100vh;
// }
// .slick-dots {
//   bottom: 20px !important;
// }
// .slick-dots li.slick-active .custom-dot {
//   // background: #fff; // Change to your active dot color
//   color: rgb(255, 255, 255);
//   border: 1px solid white;
//   font-size: 13px;
//   border-radius: 50%;
// }

// .background_video {
//   width: 100%;
//   height: 100vh;
//   object-fit: cover;
// }

// .video_overlay {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
//   z-index: 1;
// }
// .spotlight_container_three {
//   position: absolute;
//   z-index: 22;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   padding-left: 50px;
// }
// .spotlight_container_three .content {
//   width: 70%;
// }
// @media (max-width: 600px) {
//   .spotlight_container_three .content {
//     width: 90%;
//   }
// }
// .spotlight_container_three .content h4 {
//   font-size: 54px;
//   font-weight: 700;
//   color: var(--primary-color);
//   margin-bottom: 20px;
// }
// .spotlight_container_three .content p {
//   font-size: 20px;
//   color: #fff;
// }
// @media (max-width: 740px) {
//   .spotlight_container_three .content p {
//     font-size: 18px;
//   }
//   .spotlight_container_three .content h4 {
//     font-size: 42px;
//   }
// }

// @media (max-width: 480px) {
//   .spotlight_container_three .content p {
//     font-size: 16px;
//   }
//   .spotlight_container_three .content h4 {
//     font-size: 34px;
//   }
//   .spotlight_container_three {
//     padding-left: 30px;
//   }
// }
// @media (max-width: 400px) {
//   .spotlight_container_three .content p {
//     font-size: 14px;
//   }
//   .spotlight_container_three .content h4 {
//     font-size: 30px;
//   }
// }

.spotlight {
  background: url(../../assets/images/space_vehicle_webp.webp);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  @media (max-width: 480px) {
    background-position: center;
  }
  .spotlight_container {
    display: flex;
    align-items: center;
    padding: 0 50px;
    height: calc(100vh - 80px);

    @media (max-width: 480px) {
      padding: 0 30px;
    }
    .content {
      width: 70%;

      @media (max-width: 600px) {
        width: 100% !important;
      }
      h4 {
        font-size: 54px;
        font-weight: 700;
        color: #fff;
        margin-bottom: 20px;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.644);

        @media (max-width: 740px) {
          font-size: 42px !important;
        }

        @media (max-width: 480px) {
          font-size: 34px !important;
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.984);
        }
        @media (max-width: 400px) {
          font-size: 30px !important;
        }
      }
      p {
        font-size: 20px;
        color: #fff;

        @media (max-width: 740px) {
          font-size: 18px;
        }

        @media (max-width: 480px) {
          font-size: 16px;
        }
        @media (max-width: 400px) {
          font-size: 14px;
        }
      }
    }
  }
}

.slide2 {
  background: url(../../assets/images/slider_2_engineers.webp);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;

  @media (max-width: 480px) {
    background-position: center;
  }
}
.slideTwo {
  width: 50% !important;
  margin-top: 200px !important;

  @media (max-width: 1080px) {
    width: 60% !important;
  }

  @media (max-width: 740px) {
    width: 80% !important;
  }
}

.slideThree {
  width: 65% !important;
}
.slideTwo h4 {
  font-size: 40px !important;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.644);

  // font-weight: 800;
  @media (max-width: 900px) {
    font-size: 30px !important;
  }
  @media (max-width: 640px) {
    font-size: 26px !important;
  }
}
.slideThree h4 {
  font-size: 40px !important;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.644);
  @media (max-width: 900px) {
    font-size: 30px !important;
  }
  @media (max-width: 640px) {
    font-size: 26px !important;
  }
}

.slid3 {
  width: 100%;
  height: 100vh;
  position: relative; /* Ensure proper positioning of children */
}

.slid3 .background_video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.slid3 .video_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent; /* Adjust opacity as needed */
  z-index: 1; /* Ensure overlay is above the video but below the content */
}

.slid3 .spotlight_container_three {
  position: absolute;
  z-index: 2; /* Ensure content is above the overlay */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 50px;
}

.slid3 .spotlight_container_three .content {
  width: 70%;

  @media (max-width: 600px) {
    width: 90%;
  }
}

.slid3 .spotlight_container_three .content h4 {
  font-size: 54px;
  font-weight: 700;
  color: #ffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.644);
  margin-bottom: 20px;
}

.slid3 .spotlight_container_three .content p {
  font-size: 20px;
  color: #ffff;
  // text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.644);
}

@media (max-width: 740px) {
  .slid3 .spotlight_container_three .content p {
    font-size: 18px;
  }
  .slid3 .spotlight_container_three .content h4 {
    font-size: 42px;
  }
}

@media (max-width: 480px) {
  .slid3 .spotlight_container_three .content p {
    font-size: 16px;
  }
  .slid3 .spotlight_container_three .content h4 {
    font-size: 34px;
  }
  .slid3 .spotlight_container_three {
    padding-left: 30px;
  }
}

@media (max-width: 400px) {
  .slid3 .spotlight_container_three .content p {
    font-size: 14px;
  }
  .slid3 .spotlight_container_three .content h4 {
    font-size: 30px;
  }
}

.slick-dots {
  bottom: 20px !important;
}

.slick-dots li.slick-active .custom-dot {
  color: rgb(255, 255, 255);
  border: 1px solid white;
  font-size: 13px;
  border-radius: 50%;
}
