.single_product_details {
  padding: 30px 80px;


  @media (max-width: 480px) {
    padding: 30px 25px;
  }

  h5 {
    font-size: 18px;
    font-weight: 500;
    color: #808080;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
@media (max-width:800px) {
  font-size: 15px;
}
@media (max-width: 680px) {
 font-size: 12px;
 }
    
 @media (max-width: 680px) {
  font-size: 10px;
  }
  @media (max-width: 460px) {
    font-size: 8px;
  }

    svg {
      font-size: 10px;
      margin: 0 5px;
    }
    span {
      color: var(--primary-color);
    }
  }
  .product_review {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    
  @media (max-width: 480px) {
    flex-direction: column;
  }
    .product_image {
      width: 18%;
      background-color: var(--bg_item);
      padding: 30px 50px;
      border-radius: 24px;
  @media (max-width: 480px) {
   width: 60%;
  }
      img {
        width: 100%;
        display: block;
      }
    }
    .single_product_review {
      width: 75%;
      @media (max-width: 480px) {
        width: 100%;
       }
      h4 {
        font-weight: 600;
        font-size: 24px;
        color: var(--font-color);
        margin-bottom: 20px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: #808080;
      }
    }
  }


}

.product_review_mobile{
  .product_review_header{
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    .product_image {
      background-color: var(--bg_item);
      padding: 30px 50px;
      border-radius: 24px;
      width: 15%;

    @media (max-width: 960px) {
        width: 18%;
    }
    @media (max-width: 860px) {
        width: 20%;
    }
    @media (max-width: 750px) {
        width: 20%;
        padding: 35px;
    }
    @media (max-width: 580px) {
        width: 25%;
        
    }
    @media (max-width: 540px) {
      width: 30%;

      
  }



      img{
        display: block;
        width: 100%;
      }
    }
  }
  h4{
    font-size: 30px;

    @media (max-width: 960px) {
      font-size: 25px;
  }
  @media (max-width: 860px) {
      font-size: 23px;
  }
  @media (max-width: 750px) {
      font-size: 20px;
  }
  @media (max-width: 580px) {
      font-size: 16px;
  }
  }
}


