.achievements {
  padding: 80px;
}
.achievements h6 {
  font-size: 46px;
  font-weight: 600;
  color: var(--font-color);
  margin-bottom: 40px;
  font-family: "Titillium Web", sans-serif;



            // .certificate_section {
            //   width: 100%;
            //   display: flex;

            //   .img_wrapper {
            //     width: 20%;

            //     img {
            //       display: block;
            //       width: 100%;
            //     }
            //   }

            // }

       }
.achievement_list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}
.achievement_list .image_certificate {
  width: 20%;
  cursor: pointer;
}
.achievement_list .image_certificate img {
  display: block;
  width: 100%;
}

@media (max-width: 640px) {
  .achievement_list {
    flex-wrap: wrap;
  }
  .achievement_list .image_certificate {
    width: 100%;
  }
}
@media (max-width: 520px) {
  .achievements h6 {
    font-size: 38px;
  }
}
@media (max-width: 480px) {
  .achievements {
    padding: 80px 25px;
  }
}


.content_container {
  width: 100%;
  display: flex;
  margin-bottom: 50px;
  gap: 37px;

  @media ( max-width: 1500px) {
    gap: 30px

  }

  @media ( max-width: 1280px) {
    gap: 20px

  }



  @media ( max-width: 1100px) {
    flex-direction: column;

  }

  .img_wrapper {
    width: 40%;

    @media ( max-width: 1100px) {
      width: 54%;
      
    }
    @media ( max-width: 800px) {
      width: 80%;
      
    }
    @media ( max-width: 800px) {
      width: 80%;
      
    }
    @media ( max-width: 550px) {
      width: 98%;
      
    }
    @media ( max-width: 480px) {
      width: 100%;
      
    }



    img {
      width: 100%;
      border-radius: 20px;
    }
  }

  .text_wrapper {

    width: 55%;

    @media ( max-width: 1300px) {
      // width: ;
      width: 57%;
    }
    @media ( max-width: 1265px) {
      // width: ;
      width: 57%;
    }
    @media ( max-width: 1100px) {
      width: 100%;
      
    }


    h5 {
      margin-bottom: 18px;
      // display: flex;'
      font-size: 34px;
    font-weight: 600;
    color: var(--font-color);
    font-family: "Titillium Web", sans-serif;

    @media (max-width:1500px) {
      font-size: 33px;
     
  }
  @media (max-width:1380px) {
    font-size: 32px;
  }
  @media (max-width:1280px) {
    font-size: 30px;
  }

    @media ( max-width: 1260px) {

      font-size: 24px;
    }


    @media ( max-width: 1200px) {
      margin-bottom: 5px;
    }

    }

    p {
      color: var(--para_color);
    font-family: "Plus Jakarta Sans", sans-serif;
    line-height: 20px;
    font-size: 19px;
    margin-bottom: 10px;



            @media (max-width:1500px) {
                font-size: 17px;
               
            }
            @media (max-width:1380px) {
              font-size: 16px;
            }
            @media (max-width:1280px) {
              font-size: 15px;
            }

    }
  }


}

.text_container {
  width: 40%;
  margin-bottom: 20px;

  h5 {
    margin-bottom: 18px;
    // display: flex;'
    font-size: 30px;
  font-weight: 600;
  color: var(--font-color);
  font-family: "Titillium Web", sans-serif;


  }
}





