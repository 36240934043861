.intel_qulaity_sec_cotainer {
  padding: 30px 80px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 480px) {
    padding: 30px 25px;
  }

  @media (max-width: 740px) {
    flex-direction: column;
  }

  .intel_qulaity_sec_dec {
    width: 60%;

    @media (max-width: 860px) {
      width: 55%;
    }
    @media (max-width: 740px) {
      width: 100%;
      margin-bottom: 20px;
    }

    h4 {
      font-size: 30px;
      font-weight: 600;
      font-family: "Titillium Web", sans-serif;
      color: var(--font-color);
      margin-bottom: 30px;
      @media (max-width: 480px) {
        font-size: 24px;
      }
    }

    p {
      color: var(--para_color);
      font-family: "Plus Jakarta Sans", sans-serif;
    }
  }

  .intel_qulaity_sec_img {
    width: 30%;
    background-color: #e4f2f8;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;

    @media (max-width: 880px) {
      width: 35%;
    }

    @media (max-width: 860px) {
      width: 40%;
    }
    @media (max-width: 740px) {
      width: 100%;
    }

   

    img {
      width: 100%;
      border-radius: 30px;

    }
  }
}
