.prouduct_catogory_container {
  // width: 100%;
  padding: 30px 80px;

  @media (max-width: 479px) {
    padding: 30px 25px;
  }

  h5 {
    font-size: 46px;
    font-weight: 600;
    color: var(--font-color);
    margin-bottom: 20px;
    font-family: "Titillium Web", sans-serif;
    @media (max-width:520px) {

      font-size: 38px;
  
}
  }

  .prouduct_catogory_item_container {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    margin-bottom: 50px;

    .prouduct_catogory_item {
      width: 48%;
      display: flex;
      gap: 30px;
      padding: 20px;
      align-items: center;
      justify-content: center;
      background-color: #e4f2f8;
      border-radius: 12px;
      &:hover{
        
      }

      @media (max-width: 1170px) {
        width: 47%;
      }

      @media (max-width: 1170px) {
        width: 46%;
      }

      @media (max-width: 740px) {
        width: 100%;
        flex-direction: column;
        gap: 0;
      }

      .prouduct_catogory_item_img {
        width: 20%;

        img {
          width: 100%;
        }
      }

      .prouduct_catogory_item_des_div {
        width: 80%;

        @media (max-width: 740px) {
          width: 100%;
          text-align: center;
        }

        h6 {
          font-size: 23px;
          font-weight: 600;
          margin-bottom: 10px;
          font-family: "Titillium Web", sans-serif;

          @media (max-width:1500px) {
            font-size: 22px;
           
        }
        @media (max-width:1380px) {
          font-size: 21px;
        }
        @media (max-width:1280px) {
          font-size: 20px;
        }



        }
        button {
          display: flex;
          align-items: center;
          border: none;
          outline: none;
          color: #fff;
          background-color: var(--primary-color);
          border-radius: 4px;
          padding: 10px 18px;
          font-family: "Titillium Web", sans-serif;
          cursor: pointer;
          transition: 0.3s ease-in;
          @media (max-width: 740px) {
            margin: 0 auto;
            svg {
              font-size: 10px;
            }
          }
          &:hover {
            transform: scale(1.04);
            background-color: #fe6320fe;
            svg {
              translate: 10px;
              transition: 0.4s all ease-in-out;
            }
          }
        }
      }
    }
  }

  // .catologue_container {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   flex-direction: column;

  //     h4 {
  //       font-size: 28px;
  //       font-weight: 600;
  //       font-family: "Titillium Web", sans-serif;

  //     }

  //     .catologue_items {
         
  //       iframe {
  //         width: 100px;
  //         height: 80px;
  //         border-radius: 15px;
          
  //       }
  //     }
  // }


}
