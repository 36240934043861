.productbanner {
  background: url(../../assets/images/product_banner.webp);
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
  padding: 0 80px;
  @media (max-width: 570px) {
    padding: 0 40px;
  }
  .product_banner_contet {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    .product_content {
      width: 70%;
      @media (max-width: 1000px) {
        width: 90%;
      }
      @media (max-width: 681px) {
        width: 100%;
      }
      h4 {
        font-size: 54px;
        font-weight: 700;
        color: var(--primary-color);
        margin-bottom: 20px;
        font-family: "Titillium Web", sans-serif;
        @media (max-width: 800px) {
          font-size: 44px;
        }
        @media (max-width: 681px) {
          font-size: 38px;
        }
        @media (max-width: 570px) {
          font-size: 22px;
        }
      }
      p {
        font-size: 20px;
        color: #fff;
        font-family: "Plus Jakarta Sans", sans-serif;
        @media (max-width: 800px) {
          font-size: 18px;
        }
        @media (max-width: 681px) {
          font-size: 16px;
        }
        @media (max-width: 570px) {
          font-size: 14px;
        }
      }
    }
  }
}
