.adhesive {
    padding: 70px 100px;

 

    h6 {
        font-size: 18px;
        margin-bottom: 40px;
        color: #808080;
        display: flex;
        font-family: "Plus Jakarta Sans", sans-serif;
        cursor: pointer;

        @media (max-width: 480px) {
            font-size: 15px;
        }
  
        span {
          color: var(--primary-color);
          display: flex;
          align-items: center;
          cursor: pointer;
        }
    }

    @media (max-width : 912px) {
        padding: 75px;
    }
    @media (max-width : 670px) {
        padding: 60px;
    }
    @media (max-width: 480px) {
        padding: 30px 25px;
    }

       h5 {
        // text-align: center;
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .container_wrapper {

    

    .adhesive_content_contianer {
        display: flex;
        flex-direction: column;
        gap:30px;
        // background-color: rgba(48, 71, 90, 0.151);
        border-radius: 20px;
        // padding: 30px;

        p {

            font-size: 20px;


            @media (max-width:1500px) {
                font-size: 18px;
               
            }
            @media (max-width:1380px) {
              font-size: 17px;
            }
            @media (max-width:1280px) {
              font-size: 16px;
            }
       
        span {
            font-weight: 600;
            color: var(--para);
        }
    }
    }

    .adhesive_item_container {

        display: flex;
        margin-top: 20px;

        @media (max-width : 570px) {
            flex-direction: column;
            align-items: center;
            gap: 15px;
        }

        

        .adhesive_item {
            display: flex;
            width: 50%;
            align-items: center;
            flex-direction: column;

            @media (max-width : 570px) { 

                width: 100%;
            }

                .adhesive_item_img {
                    width: 60%;

                    img {
                        width: 100%;
                    }
                }

                .adhesive_item_name_container {
                    width: 60%;
                    display: flex;
                    gap: 15px;
                    margin-left: 20px;
                    padding-left: 20px;


                    @media (max-width : 900px) {
                        width: 82%;
                    }

                    @media (max-width : 640px) {
                        padding: 0;
                    }

                    @media (max-width : 570px) { 

                        width: 85%;
                    }

                    .item_name {
                        width: 60%;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        


                        p {
                            font-size: 20px;
                            font-weight: 700;

                            @media (max-width : 530px) {
                                font-size: 15px;
                            }
                            @media (max-width : 570px) { 

                                font-size: 18px;
                            }
                

                        }
                        // h6 {
                        //     font-size: 18px;
                        //     font-size: 500;
                        //     margin: 0;
                        // }
                        span {
                            // font-size: 16px;

                            font-size: 20px;


            @media (max-width:1500px) {
                font-size: 18px;
               
            }
            @media (max-width:1380px) {
              font-size: 17px;
            }
            @media (max-width:1280px) {
              font-size: 16px;
            }

                            @media (max-width : 640px) {
                                font-size: 13px;
                            }

                            @media (max-width : 570px) { 

                                font-size: 16px;
                            }
                
                        }
                    }

                    .item_desc  {
                        width: 30%;
                        border-left:2px solid rgba(0, 0, 0, 0.856) ;
                        height: 60%;
                        padding-left:15px ;

                        p {
                            font-weight: 700;

                            @media (max-width : 530px) {
                                font-size: 15px;
                            }

                            @media (max-width : 570px) { 

                                font-size: 18px;
                            }
                
                        }
                        span {
                           

                            font-size: 20px;


            @media (max-width:1500px) {
                font-size: 18px;
               
            }
            @media (max-width:1380px) {
              font-size: 17px;
            }
            @media (max-width:1280px) {
              font-size: 16px;
            }

                            @media (max-width : 640px) {
                                font-size: 12px;
                            }
                            
                            @media (max-width : 570px) { 

                                font-size: 16px;
                            }
                        }
                    }

                }
        }
    }

}



}
